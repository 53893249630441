.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.container {
  text-align: left;
}

.titleText {
  margin-bottom: 23px;
}

.buttonSubscribe {
  margin-bottom: 46px;
}

@media screen and (max-width: 770px) {
  .title {
    display: block;
  }
}
